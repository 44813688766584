<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <sequenciamento-eletrico-data-table
          :items="items"
          :loading="loading"
          @rechargeTable="getSequenciamentosEletricos"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SequenciamentoEletricoService from '@/services/SequenciamentoEletricoService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  mixins: [refreshDataMixins],
  components: {
    SequenciamentoEletricoDataTable: () =>
      import(
        '@/components/perdas-tecnicas/sequenciamento-eletrico/SequenciamentoEletricoDataTable'
      )
  },
  data: () => ({
    items: [],
    loading: false
  }),
  created() {
    this.refreshData(this.getSequenciamentosEletricos);
  },
  methods: {
    getSequenciamentosEletricos() {
      this.loading = true;
      SequenciamentoEletricoService.getAll()
        .then(({ data }) => (this.items = data.data))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
